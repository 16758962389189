import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { overlay } from "../styles/util"
import { css } from "@emotion/core"

const JazzBlogPage = ({ data }) => (
  <Layout>
    <SEO title="Jazz Blog" />
    <Img css={overlay} alt="Photo of Hunter playing a guitar" fluid={data.file.childImageSharp.fluid} />
    <section css={styles.section}>
      <p>
        The goal of this blog is to regularly record myself improvising and have a short-term archive of those
        improvisations to which I can refer to get a sense for how I'm progressing. I tend to fall into the trap
        of spending all my time working on exercises, comping, vocab, etc. and not actually improvising over tunes.
        That stuff is also valuable, but I want to structure my practices around performing tunes in their entirety.
      </p>
      <p>
        So here is a list of the 7 latest tunes I've recorded. Expected to be updated regularly. I won't restrict myself
        by promising to play the same tunes multiple days or obligating myself to record the entire harmony. I'll do
        whatever strikes me at the time. This is as much for me as it is for someone interested in hearing someone else
        figuring out this whole jazz thing.
      </p>
      <iframe title="Jazz Blog Playlist" width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/728560779&color=%234682B4&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
  audioContainer: css`
    padding: 16px;
    border-top: 2px solid steelblue;

    audio {
      width: 100%;
      max-width: 320px;
      margin-bottom: 1.45rem;
    }

    p {
      margin-bottom: 0;
    }
  `,
}

export default JazzBlogPage

export const query = graphql`
  query {
    file(relativePath: { eq: "guitar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
